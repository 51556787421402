import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Preguntas frecuentes
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
              Como adquiero acceso a Condominio 506?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              Puedes contactarnos al correo
              <Link> info@condominio506.com </Link>
              o al chat de Whatsapp en la sección de contacto. El proceso se basa en llenar un formulario corto con 
              los datos del condominio y una vez procesado en nuestros sistemas se procede a crear una cuenta
              administradora maestra y un código de condominio los cuales son enviados en un correo electrónico de bienvenida junto con 
              la contraseña. Con esta cuenta maestra podrás agregar otros administradores, oficiales de seguridad
              y residentes al sistema y empezar hacer uso de todos los módulos de la app.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
              Como se calcula el precio mensual que debo pagar?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              El precio mensual será calculado automaticamente en nuestro sistema de cobros. El cálculo se 
              hace basado en la cantidad de usuarios activos en tu cuenta de Condominio 506 más el 13% de IVA. Cada cuenta 
              que crees para tu condominio será calculada para el cobro mensual, esto incluye cuentas de administradores, oficiales 
              de seguridad y residentes (propietarios e inquilinos). 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
              Como puedo aprendar a usar la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              Actualmente estamos trabajando en la creación de una base de datos de conocimientos. En esta base de datos con interfaz grafica
              encontrarás guías e instrucciones de como usar cada módulo paso a paso para sacar el mayor provecho de ellos. Mientas tanto a cada cliente nuevo
              le estamos ofreciendo un acompañamiento virtual o presencial de 2 horas para ayudar en el proceso de implementación de la plataforma, 
              evacuar dudas o hacer entrenamientos cortos con propietarios u otros usuarios del sistema. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
              Debo tener algún tipo de infraestructura tecnológica como servidores, routers o bases de datos para hacer uso de la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              No, condominio 506 es una plataforma SAAS (Software as a Service) por sus siglas en inglés por lo tanto toda la infraestructura 
              está desplegada en la Nube. Condominio 506 es una plataforma ERP de administración de condominios, multi-condominio, RBAC (Role Based) por sus siglas en inglés, 
              y multi-plataforma (PWA) que puede usarse en cualquier computadora de escritorio, laptop, tablet o teléfono inteligente. No necesitas ningún tipo de equipo ni conocimientos técnicos. El manteniemiento, soporte y desarrollo 
              es brindado por Software Factory CR quien es el desarrollador de la aplicación. El único requisito es una connección a internet, preferiblemente de banda ancha para un mejor rendimiento.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
              Condominio 506 tiene apps para télefonos Android y IOS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              Condominio 506 es una aplicación web que usa técnología PWA lo cual permite la instalación de la app en cualquier teléfono inteligente
              o cualquier otro dispositivo. Condominio 506 no usa Apps nativas para sistemas operativos Android ó IOS en su lugar la instalación se hace
              accediendo al sitio web <Link>www.condominio506.com</Link> desde el navegador de tu teléfono y usando la opción "Más" (Los tres puntos verticales) ubicada a la derecha de la barra de direcciones luego
              seleccionar "Añadir a la pantalla de inicio". No hay que confundiar esta opción con la de crear un acceso directo, la diferencia radica
              en la tecnología PWA que no sólo te da una sensación (look & feel) nativo sino que además administra la sessión, cookies y cache de la aplicación local. 
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
