import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Residentes" src="/static/images/avatar/1.jpg" />,
    name: 'Residentes',
    occupation: 'Directorio principal',
    testimonial:
      "El módulo de Residentes es una base de datos completa y centralizada que almacena toda la información relevante de los residentes, incluyendo detalles de contacto, vehículos, mascotas y cohabitantes. Diseñado para ser intuitivo y accesible, este módulo permite a los administradores y oficiales de seguridad gestionar y actualizar la información de manera rápida y eficiente, garantizando un control preciso y ordenado de todos los residentes del condominio.",
  },
  {
    avatar: <Avatar alt="Coutas" src="/static/images/avatar/2.jpg" />,
    name: 'Cuotas',
    occupation: 'Cuotas condominales',
    testimonial:
      "El módulo de Cuotas facilita la gestión y registro de las cuotas condominales, permitiendo la captura detallada de pagos y su posterior aprobación por parte del administrador. Con opciones de filtrado por mes, este módulo proporciona un control eficiente de las cuotas y la morosidad, mejorando la administración financiera. Los datos capturados se integran automáticamente en el balance mensual de ingresos y egresos, asegurando una visión clara y precisa del estado financiero del condominio.",
  },
  {
    avatar: <Avatar alt="Control de Ingreso" src="/static/images/avatar/3.jpg" />,
    name: 'Control de ingreso',
    occupation: 'Autorizaciones - Visitas',
    testimonial:
      'Módulo de Control de Ingreso: Dividido en dos submódulos, Autorizaciones y Visitas, este sistema gestiona el acceso al condominio de manera eficiente. Autorizaciones emplea algoritmos avanzados y códigos alfanuméricos o QR para validar y preautorizar entradas de personas, asegurando un acceso seguro para visitantes previamente registrados. Visitas permite a los oficiales de seguridad manejar y registrar la entrada de visitantes no preautorizados, proporcionando toda la información necesaria para una validación adecuada y un control exhaustivo del ingreso en la base de datos.',
  },
  {
    avatar: <Avatar alt="Reservaciones y Amenidades" src="/static/images/avatar/4.jpg" />,
    name: 'Reservaciones & amenidades',
    occupation: 'Reservaciones y Amenidades',
    testimonial:
      "Módulo de Reservaciones y Amenidades: Compuesto por dos submódulos interconectados, este sistema optimiza la gestión de reservas de amenidades en el condominio. Reservaciones permite realizar reservas tanto por día completo como por hora, adaptándose a diversas necesidades. Amenidades ofrece una interfaz gráfica interactiva que proporciona una experiencia de usuario excepcional, permitiendo a los residentes explorar y conocer en detalle cada amenidad antes de hacer una reserva, facilitando una elección informada y satisfactoria.",
  },
  {
    avatar: <Avatar alt="Mensajería Interna" src="/static/images/avatar/5.jpg" />,
    name: 'Mensajería Interna',
    occupation: 'Mensajería instantanea',
    testimonial:
      "Este módulo facilita la comunicación instantánea entre residentes, administradores y personal de seguridad mediante un sistema de mensajería con notificaciones, sonidos y alertas. Permite compartir imágenes y documentos, asegurando una gestión precisa y clara de las interacciones y eventos dentro del condominio.",
  },
  {
    avatar: <Avatar alt="Finanzas" src="/static/images/avatar/6.jpg" />,
    name: 'Finanzas',
    occupation: 'Finanzas',
    testimonial:
      "Este módulo automatiza el seguimiento y la creación de balances de ingresos y egresos, manejando transacciones tanto en colones como en dólares. Genera dinámicamente tres cuadros comparativos para facilitar la comprensión de la situación financiera en tiempo real. Accesible a todos los usuarios, proporciona una visión clara y detallada de la administración financiera del condominio.",
  },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Módulos
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.primary', fontSize: '16px' }}>
          Nuesta app integra un total de 12 módulos para administración y gestión. Acá te dejamos una introducción a los principales módulos de Condominio 506.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.primary', fontSize: '16px' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />                
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
