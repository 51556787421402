import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Alto rendimiento',
    description:
      'Condominio 506 garantiza un rendimiento excepcional y una alta velocidad de procesamiento, optimizando la eficiencia en la gestión de datos y asegurando una experiencia fluida y ágil para todos los usuarios.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Stack',
    description:
      'El uso del MERN stack para Condominio 506 proporciona una integración fluida entre MongoDB, Express.js, React y Node.js, ofreciendo un desarrollo ágil, escalabilidad robusta y un rendimiento óptimo que potencia la eficiencia y la experiencia del usuario.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Experiencia de usuario UI/UX',
    description:
      'Condominio 506 ofrece una experiencia de usuario excepcional con una interfaz intuitiva y un diseño centrado en el usuario, garantizando una navegación fluida y una interacción eficiente en todas las funcionalidades de la aplicación.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Innovación',
    description:
      'Condominio 506 se distingue por su innovación al integrar tecnología avanzada para la gestión integral de condominios, ofreciendo soluciones personalizadas, automatización eficiente y una experiencia de usuario moderna y adaptable.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Soporte',
    description:
      'Condominio 506 proporciona un soporte integral y proactivo, asegurando asistencia rápida y eficaz para resolver cualquier incidencia, optimizar el uso de la plataforma y garantizar una experiencia continua y sin interrupciones.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precisión en cada detalle',
    description:
      'Condominio 506 se destaca por su precisión en cada detalle, asegurando una gestión meticulosa y exacta de cada aspecto del condominio para ofrecer una administración eficiente y sin errores.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Rendimiento
          </Typography>
          <Typography variant="body1" sx={{ color: '#ffffff', textAlign: "justify", fontSize: '16px' }}>
          Condominio 506 es una webapp avanzada, diseñada para gestionar, almacenar y procesar grandes volúmenes de datos de manera rápida y eficiente. Utilizamos un stack tecnológico de vanguardia que incluye lenguajes de programación, bibliotecas, servidores e infraestructura moderna. Este entorno nos permite ofrecer soluciones innovadoras y mantenernos a la vanguardia en la administración de condominios.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium', color: Text.primary, fontSize: '16px' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: Text.primary, fontSize: '16px' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
