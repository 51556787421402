import React from 'react';
import MarketingPage from './MarketingPage';

function App() {
  return (
    <div>
      <MarketingPage />     
    </div>
  );
}

export default App;
