import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import { Icon, Typography } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

    // Function to handle smooth scrolling to specific section
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      setOpen(false); // Close the drawer if it's open
    };  

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 10 }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>    
          <WindowIcon sx={{ color: 'text.primary', mr: 1, cursor: 'pointer' }} onClick={() => scrollToSection('hero')} />           
          <Typography
            component="h2"
            variant="h4"
            sx={{
              color: 'text.primary',
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'left' },
              cursor: 'pointer',
            }}
            onClick={() => scrollToSection('hero')}
          >
            Condominio 506
          </Typography> 
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('features')}>
                Características
              </Button>
              <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('testimonials')}>
                Módulos
              </Button>
              <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('highlights')}>
                Rendimiento
              </Button>
              <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('pricing')}>
                Precio
              </Button>
              <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('faq')}>
                Preguntas
              </Button>
              <Button variant="text" color="info" size="medium" onClick={() => scrollToSection('footer')}>
                Contacto
              </Button>
            </Box>
          </Box>
          
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => scrollToSection('features')}>Características</MenuItem>
                <MenuItem onClick={() => scrollToSection('highlights')}>Módulos</MenuItem>
                <MenuItem onClick={() => scrollToSection('testimonials')}>Rendiiento</MenuItem>
                <MenuItem onClick={() => scrollToSection('pricing')}>Precio</MenuItem>
                <MenuItem onClick={() => scrollToSection('faq')}>Preguntas</MenuItem>
                <MenuItem onClick={() => scrollToSection('footer')}>Contacto</MenuItem>
                
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
